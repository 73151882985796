<template>
  <div class="payment-config-editor" data-test-id="paymentConfigEditor">
    <v-toolbar dense class="payment-config-editor-toolbar">
      <v-tabs grow>
        <v-tab
          v-for="tab in tabs"
          :key="tab.name"
          :to="tab.route"
          :disabled="loading"
          :data-test-id="tab.route.name + 'Tab'"
        >
          {{ tab.name }}
        </v-tab>
      </v-tabs>
    </v-toolbar>
    <router-view class="payment-config-editor-content" :key="$route.name" />
  </div>
</template>

<script>
import mainOverviewMixin from "mixins/main-overview-mixin";
export default {
  mixins: [mainOverviewMixin],
  provide() {
    return {
      getEditorHeight: () => {
        return (
          this.detailHeight -
          this.$vToolbarHeightDense -
          this.$vToolbarMarginBottom
        );
      },
    };
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    tabs() {
      return [
        {
          name: "Payment Service Providers",
          route: {
            name: "paymentProviders",
            params: this.$route.params,
          },
        },

        {
          name: "Merchants",
          route: {
            name: "paymentMerchants",
            params: this.$route.params,
          },
        },

        {
          name: "Methods",
          route: {
            name: "paymentMethods",
            params: this.$route.params,
          },
        },
      ];
    },
  },
};
</script>

<style scoped>
.payment-config-editor {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  justify-content: flex-start;
  height: 100%;
  overflow: hidden;
}

.payment-config-editor > .payment-config-editor-toolbar {
  margin-top: 12px;
  flex: 0 1 auto;
}

.payment-config-editor > .payment-config-editor-content {
  height: calc(100% - 60px);
  overflow: scroll;
}
</style>